import * as Utils from '../../Utils';

var keyModifier = Utils.getKeyModifier();

var defaultKeymap = {
    DataGridPagination: {
        CONFIRM: 'enter',
    },
};

export default defaultKeymap;
