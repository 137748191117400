import {Utils} from 'components/shared';

var keyModifier = Utils.getKeyModifier();

var defaultKeymap = {
    DescItemJsonTableCellForm: {
        FORM_CLOSE: 'enter',
    },
};

export default defaultKeymap;
