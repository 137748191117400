import React from 'react';

const Folder = props => {
    return (
        <svg {...props}>
            <path
                id="path18"
                d="m 1.0173982,14.64121 c 0,3.792386 -1.2547689,3.34281 15.3936178,3.34281 3.35226,0.01914 2.414687,-0.643515 2.606494,-9.736355 0,-4.6387955 -0.105371,-3.7746645 -9.8734724,-3.7746645 C 6.3093059,1.6382512 8.1807055,2.0353909 3.3679417,2.0353909 c -2.7043384,0 -2.3794911,1.2335597 -2.3505435,12.6058961 z"
            />
        </svg>
    );
};
export default Folder;
