/* tslint:disable */
/* eslint-disable */
/**
 * ELZA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from './base';
/**
 * States of ApState
 * @export
 * @enum {string}
 */
export const ApStateApproval = {
    New: 'NEW',
    ToApprove: 'TO_APPROVE',
    Approved: 'APPROVED',
    ToAmend: 'TO_AMEND'
};
/**
 *
 * @export
 * @enum {string}
 */
export const ExportRequestState = {
    Pending: 'PENDING',
    Preparing: 'PREPARING',
    Finished: 'FINISHED'
};
export const FieldValueFilterOperationEnum = {
    Eq: 'EQ',
    Neq: 'NEQ',
    Gt: 'GT',
    Lt: 'LT',
    Gte: 'GTE',
    Lte: 'LTE',
    Startwith: 'STARTWITH',
    Endwith: 'ENDWITH',
    Contains: 'CONTAINS',
    IsNull: 'IS_NULL',
    NotNull: 'NOT_NULL'
};
/**
 * File system item type
 * @export
 * @enum {string}
 */
export const FsItemType = {
    Folder: 'FOLDER',
    File: 'FILE'
};
export const LogicalFilterOperationEnum = {
    And: 'AND',
    Or: 'OR'
};
/**
 * Method of replacing ApAccessPoint
 * @export
 * @enum {string}
 */
export const ReplaceType = {
    Simple: 'SIMPLE',
    CopyAll: 'COPY_ALL'
};
/**
 * States of ApRevState
 * @export
 * @enum {string}
 */
export const RevisionState = {
    Active: 'ACTIVE',
    ToApprove: 'TO_APPROVE',
    ToAmend: 'TO_AMEND'
};
export const SortingOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
};
/**
 * AccesspointsApi - axios parameter creator
 * @export
 */
export const AccesspointsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Change state of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeState: (id, apStateUpdate, apVersion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accessPointChangeState', 'id', id);
            // verify required parameter 'apStateUpdate' is not null or undefined
            assertParamExists('accessPointChangeState', 'apStateUpdate', apStateUpdate);
            const localVarPath = `/accesspoint/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (apVersion !== undefined) {
                localVarQueryParameter['apVersion'] = apVersion;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apStateUpdate, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Change state of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {RevStateChange} revStateChange data for change state of revision of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeStateRevision: (id, revStateChange, apVersion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accessPointChangeStateRevision', 'id', id);
            // verify required parameter 'revStateChange' is not null or undefined
            assertParamExists('accessPointChangeStateRevision', 'revStateChange', revStateChange);
            const localVarPath = `/accesspoint/{id}/revision/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (apVersion !== undefined) {
                localVarQueryParameter['apVersion'] = apVersion;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(revStateChange, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Copying an archive entity
         * @param {string} id id of accesspoint
         * @param {CopyAccessPointDetail} copyAccessPointDetail additional copy options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointCopyAccessPoint: (id, copyAccessPointDetail, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accessPointCopyAccessPoint', 'id', id);
            // verify required parameter 'copyAccessPointDetail' is not null or undefined
            assertParamExists('accessPointCopyAccessPoint', 'copyAccessPointDetail', copyAccessPointDetail);
            const localVarPath = `/accesspoint/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(copyAccessPointDetail, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Invalidation of ApAccessPoint (delete/replace)
         * @param {string} id id of accesspoint
         * @param {DeleteAccessPointDetail} [deleteAccessPointDetail] addition for method of invalidation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteAccessPoint: (id, deleteAccessPointDetail, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accessPointDeleteAccessPoint', 'id', id);
            const localVarPath = `/accesspoint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(deleteAccessPointDetail, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete part of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeletePart: (id, partId, apVersion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accessPointDeletePart', 'id', id);
            // verify required parameter 'partId' is not null or undefined
            assertParamExists('accessPointDeletePart', 'partId', partId);
            const localVarPath = `/accesspoint/{id}/part/{partId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"partId"}}`, encodeURIComponent(String(partId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (apVersion !== undefined) {
                localVarQueryParameter['apVersion'] = apVersion;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete part of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteRevisionPart: (id, partId, apVersion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accessPointDeleteRevisionPart', 'id', id);
            // verify required parameter 'partId' is not null or undefined
            assertParamExists('accessPointDeleteRevisionPart', 'partId', partId);
            const localVarPath = `/accesspoint/{id}/revision/part/{partId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"partId"}}`, encodeURIComponent(String(partId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (apVersion !== undefined) {
                localVarQueryParameter['apVersion'] = apVersion;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List of automatically generated elements of the description
         * @param {string} id id of accesspoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointGetAutoitems: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accessPointGetAutoitems', 'id', id);
            const localVarPath = `/accesspoint/{id}/autoitems`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Merge ApRevision & ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointMergeRevision: (id, apStateUpdate, apVersion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accessPointMergeRevision', 'id', id);
            // verify required parameter 'apStateUpdate' is not null or undefined
            assertParamExists('accessPointMergeRevision', 'apStateUpdate', apStateUpdate);
            const localVarPath = `/accesspoint/{id}/revision/merge`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (apVersion !== undefined) {
                localVarQueryParameter['apVersion'] = apVersion;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apStateUpdate, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Set the preferred name of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferName: (id, partId, apVersion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accessPointSetPreferName', 'id', id);
            // verify required parameter 'partId' is not null or undefined
            assertParamExists('accessPointSetPreferName', 'partId', partId);
            const localVarPath = `/accesspoint/{id}/part/{partId}/prefer-name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"partId"}}`, encodeURIComponent(String(partId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (apVersion !== undefined) {
                localVarQueryParameter['apVersion'] = apVersion;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Set the preferred name of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferNameRevision: (id, partId, apVersion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accessPointSetPreferNameRevision', 'id', id);
            // verify required parameter 'partId' is not null or undefined
            assertParamExists('accessPointSetPreferNameRevision', 'partId', partId);
            const localVarPath = `/accesspoint/{id}/revision/part/{partId}/prefer-name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"partId"}}`, encodeURIComponent(String(partId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (apVersion !== undefined) {
                localVarQueryParameter['apVersion'] = apVersion;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Vytvoření nové revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRevision: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createRevision', 'id', id);
            const localVarPath = `/revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Zneplatnění přístupovych bodu
         * @param {DeleteAccessPointsDetail} [deleteAccessPointsDetail] Seznam přístupových bodů
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessPoints: (deleteAccessPointsDetail, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/accesspoints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(deleteAccessPointsDetail, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Smazání revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRevision: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRevision', 'id', id);
            const localVarPath = `/revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Vrátí seznam automaticky generovaných prvků popisu pro revizi
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevAutoitems: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRevAutoitems', 'id', id);
            const localVarPath = `/revision/{id}/autoitems`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Obnovení neplatné entity a návrat do původního stavu
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccessPoint: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restoreAccessPoint', 'id', id);
            const localVarPath = `/accesspoints/{id}/restore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Ověřování entity
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAccessPoint: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('validateAccessPoint', 'id', id);
            const localVarPath = `/accesspoints/{id}/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AccesspointsApi - functional programming interface
 * @export
 */
export const AccesspointsApiFp = function (configuration) {
    const localVarAxiosParamCreator = AccesspointsApiAxiosParamCreator(configuration);
    return {
        /**
         * Change state of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeState(id, apStateUpdate, apVersion, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.accessPointChangeState(id, apStateUpdate, apVersion, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.accessPointChangeState']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Change state of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {RevStateChange} revStateChange data for change state of revision of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeStateRevision(id, revStateChange, apVersion, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.accessPointChangeStateRevision(id, revStateChange, apVersion, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.accessPointChangeStateRevision']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Copying an archive entity
         * @param {string} id id of accesspoint
         * @param {CopyAccessPointDetail} copyAccessPointDetail additional copy options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointCopyAccessPoint(id, copyAccessPointDetail, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.accessPointCopyAccessPoint(id, copyAccessPointDetail, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.accessPointCopyAccessPoint']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Invalidation of ApAccessPoint (delete/replace)
         * @param {string} id id of accesspoint
         * @param {DeleteAccessPointDetail} [deleteAccessPointDetail] addition for method of invalidation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteAccessPoint(id, deleteAccessPointDetail, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.accessPointDeleteAccessPoint(id, deleteAccessPointDetail, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.accessPointDeleteAccessPoint']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Delete part of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeletePart(id, partId, apVersion, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.accessPointDeletePart(id, partId, apVersion, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.accessPointDeletePart']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Delete part of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteRevisionPart(id, partId, apVersion, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.accessPointDeleteRevisionPart(id, partId, apVersion, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.accessPointDeleteRevisionPart']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * List of automatically generated elements of the description
         * @param {string} id id of accesspoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointGetAutoitems(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.accessPointGetAutoitems(id, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.accessPointGetAutoitems']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Merge ApRevision & ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointMergeRevision(id, apStateUpdate, apVersion, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.accessPointMergeRevision(id, apStateUpdate, apVersion, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.accessPointMergeRevision']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Set the preferred name of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferName(id, partId, apVersion, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.accessPointSetPreferName(id, partId, apVersion, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.accessPointSetPreferName']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Set the preferred name of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferNameRevision(id, partId, apVersion, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.accessPointSetPreferNameRevision(id, partId, apVersion, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.accessPointSetPreferNameRevision']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @summary Vytvoření nové revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRevision(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createRevision(id, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.createRevision']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @summary Zneplatnění přístupovych bodu
         * @param {DeleteAccessPointsDetail} [deleteAccessPointsDetail] Seznam přístupových bodů
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessPoints(deleteAccessPointsDetail, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAccessPoints(deleteAccessPointsDetail, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.deleteAccessPoints']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @summary Smazání revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRevision(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteRevision(id, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.deleteRevision']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @summary Vrátí seznam automaticky generovaných prvků popisu pro revizi
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevAutoitems(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRevAutoitems(id, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.getRevAutoitems']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @summary Obnovení neplatné entity a návrat do původního stavu
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccessPoint(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.restoreAccessPoint(id, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.restoreAccessPoint']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @summary Ověřování entity
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAccessPoint(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.validateAccessPoint(id, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AccesspointsApi.validateAccessPoint']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * AccesspointsApi - factory interface
 * @export
 */
export const AccesspointsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = AccesspointsApiFp(configuration);
    return {
        /**
         * Change state of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeState(id, apStateUpdate, apVersion, options) {
            return localVarFp.accessPointChangeState(id, apStateUpdate, apVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * Change state of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {RevStateChange} revStateChange data for change state of revision of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeStateRevision(id, revStateChange, apVersion, options) {
            return localVarFp.accessPointChangeStateRevision(id, revStateChange, apVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * Copying an archive entity
         * @param {string} id id of accesspoint
         * @param {CopyAccessPointDetail} copyAccessPointDetail additional copy options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointCopyAccessPoint(id, copyAccessPointDetail, options) {
            return localVarFp.accessPointCopyAccessPoint(id, copyAccessPointDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * Invalidation of ApAccessPoint (delete/replace)
         * @param {string} id id of accesspoint
         * @param {DeleteAccessPointDetail} [deleteAccessPointDetail] addition for method of invalidation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteAccessPoint(id, deleteAccessPointDetail, options) {
            return localVarFp.accessPointDeleteAccessPoint(id, deleteAccessPointDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete part of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeletePart(id, partId, apVersion, options) {
            return localVarFp.accessPointDeletePart(id, partId, apVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete part of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteRevisionPart(id, partId, apVersion, options) {
            return localVarFp.accessPointDeleteRevisionPart(id, partId, apVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * List of automatically generated elements of the description
         * @param {string} id id of accesspoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointGetAutoitems(id, options) {
            return localVarFp.accessPointGetAutoitems(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Merge ApRevision & ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointMergeRevision(id, apStateUpdate, apVersion, options) {
            return localVarFp.accessPointMergeRevision(id, apStateUpdate, apVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * Set the preferred name of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferName(id, partId, apVersion, options) {
            return localVarFp.accessPointSetPreferName(id, partId, apVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * Set the preferred name of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferNameRevision(id, partId, apVersion, options) {
            return localVarFp.accessPointSetPreferNameRevision(id, partId, apVersion, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Vytvoření nové revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRevision(id, options) {
            return localVarFp.createRevision(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Zneplatnění přístupovych bodu
         * @param {DeleteAccessPointsDetail} [deleteAccessPointsDetail] Seznam přístupových bodů
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessPoints(deleteAccessPointsDetail, options) {
            return localVarFp.deleteAccessPoints(deleteAccessPointsDetail, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Smazání revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRevision(id, options) {
            return localVarFp.deleteRevision(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Vrátí seznam automaticky generovaných prvků popisu pro revizi
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevAutoitems(id, options) {
            return localVarFp.getRevAutoitems(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Obnovení neplatné entity a návrat do původního stavu
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccessPoint(id, options) {
            return localVarFp.restoreAccessPoint(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Ověřování entity
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAccessPoint(id, options) {
            return localVarFp.validateAccessPoint(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AccesspointsApi - object-oriented interface
 * @export
 * @class AccesspointsApi
 * @extends {BaseAPI}
 */
export class AccesspointsApi extends BaseAPI {
    /**
     * Change state of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {ApStateUpdate} apStateUpdate data for change ApState
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    accessPointChangeState(id, apStateUpdate, apVersion, options) {
        return AccesspointsApiFp(this.configuration).accessPointChangeState(id, apStateUpdate, apVersion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Change state of revision of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {RevStateChange} revStateChange data for change state of revision of ApAccessPoint
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    accessPointChangeStateRevision(id, revStateChange, apVersion, options) {
        return AccesspointsApiFp(this.configuration).accessPointChangeStateRevision(id, revStateChange, apVersion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Copying an archive entity
     * @param {string} id id of accesspoint
     * @param {CopyAccessPointDetail} copyAccessPointDetail additional copy options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    accessPointCopyAccessPoint(id, copyAccessPointDetail, options) {
        return AccesspointsApiFp(this.configuration).accessPointCopyAccessPoint(id, copyAccessPointDetail, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Invalidation of ApAccessPoint (delete/replace)
     * @param {string} id id of accesspoint
     * @param {DeleteAccessPointDetail} [deleteAccessPointDetail] addition for method of invalidation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    accessPointDeleteAccessPoint(id, deleteAccessPointDetail, options) {
        return AccesspointsApiFp(this.configuration).accessPointDeleteAccessPoint(id, deleteAccessPointDetail, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete part of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {number} partId partId of ApAccessPoint
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    accessPointDeletePart(id, partId, apVersion, options) {
        return AccesspointsApiFp(this.configuration).accessPointDeletePart(id, partId, apVersion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete part of revision of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {number} partId partId of revision
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    accessPointDeleteRevisionPart(id, partId, apVersion, options) {
        return AccesspointsApiFp(this.configuration).accessPointDeleteRevisionPart(id, partId, apVersion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List of automatically generated elements of the description
     * @param {string} id id of accesspoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    accessPointGetAutoitems(id, options) {
        return AccesspointsApiFp(this.configuration).accessPointGetAutoitems(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Merge ApRevision & ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {ApStateUpdate} apStateUpdate data for change ApState
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    accessPointMergeRevision(id, apStateUpdate, apVersion, options) {
        return AccesspointsApiFp(this.configuration).accessPointMergeRevision(id, apStateUpdate, apVersion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Set the preferred name of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {number} partId partId of ApAccessPoint
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    accessPointSetPreferName(id, partId, apVersion, options) {
        return AccesspointsApiFp(this.configuration).accessPointSetPreferName(id, partId, apVersion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Set the preferred name of revision of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {number} partId partId of revision
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    accessPointSetPreferNameRevision(id, partId, apVersion, options) {
        return AccesspointsApiFp(this.configuration).accessPointSetPreferNameRevision(id, partId, apVersion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Vytvoření nové revize přístupového bodu
     * @param {number} id Identifikátor archivní entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    createRevision(id, options) {
        return AccesspointsApiFp(this.configuration).createRevision(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Zneplatnění přístupovych bodu
     * @param {DeleteAccessPointsDetail} [deleteAccessPointsDetail] Seznam přístupových bodů
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    deleteAccessPoints(deleteAccessPointsDetail, options) {
        return AccesspointsApiFp(this.configuration).deleteAccessPoints(deleteAccessPointsDetail, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Smazání revize přístupového bodu
     * @param {number} id Identifikátor archivní entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    deleteRevision(id, options) {
        return AccesspointsApiFp(this.configuration).deleteRevision(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Vrátí seznam automaticky generovaných prvků popisu pro revizi
     * @param {string} id Identifikátor archivní entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    getRevAutoitems(id, options) {
        return AccesspointsApiFp(this.configuration).getRevAutoitems(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Obnovení neplatné entity a návrat do původního stavu
     * @param {string} id Identifikátor archivní entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    restoreAccessPoint(id, options) {
        return AccesspointsApiFp(this.configuration).restoreAccessPoint(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Ověřování entity
     * @param {string} id Identifikátor archivní entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    validateAccessPoint(id, options) {
        return AccesspointsApiFp(this.configuration).validateAccessPoint(id, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Copy user permissions from another user  Method will append all permissions to given user
         * @param {number} userId ID of target user
         * @param {AdminCopyPermissionParams} adminCopyPermissionParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCopyPermissions: (userId, adminCopyPermissionParams, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminCopyPermissions', 'userId', userId);
            // verify required parameter 'adminCopyPermissionParams' is not null or undefined
            assertParamExists('adminCopyPermissions', 'adminCopyPermissionParams', adminCopyPermissionParams);
            const localVarPath = `/admin/user/{userId}/copy-permissions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(adminCopyPermissionParams, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Return basic statistical info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInfo: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Return list of logged users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoggedUsers: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/info/logged-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Creating missing items in arr_cached_node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSyncNodeCache: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/admin/arrangement/missing-cached-nodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration);
    return {
        /**
         * Copy user permissions from another user  Method will append all permissions to given user
         * @param {number} userId ID of target user
         * @param {AdminCopyPermissionParams} adminCopyPermissionParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCopyPermissions(userId, adminCopyPermissionParams, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.adminCopyPermissions(userId, adminCopyPermissionParams, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AdminApi.adminCopyPermissions']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Return basic statistical info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInfo(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.adminInfo(options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AdminApi.adminInfo']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Return list of logged users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoggedUsers(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.adminLoggedUsers(options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AdminApi.adminLoggedUsers']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Creating missing items in arr_cached_node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSyncNodeCache(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.adminSyncNodeCache(options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['AdminApi.adminSyncNodeCache']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration, basePath, axios) {
    const localVarFp = AdminApiFp(configuration);
    return {
        /**
         * Copy user permissions from another user  Method will append all permissions to given user
         * @param {number} userId ID of target user
         * @param {AdminCopyPermissionParams} adminCopyPermissionParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCopyPermissions(userId, adminCopyPermissionParams, options) {
            return localVarFp.adminCopyPermissions(userId, adminCopyPermissionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Return basic statistical info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInfo(options) {
            return localVarFp.adminInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Return list of logged users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoggedUsers(options) {
            return localVarFp.adminLoggedUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Creating missing items in arr_cached_node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSyncNodeCache(options) {
            return localVarFp.adminSyncNodeCache(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Copy user permissions from another user  Method will append all permissions to given user
     * @param {number} userId ID of target user
     * @param {AdminCopyPermissionParams} adminCopyPermissionParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    adminCopyPermissions(userId, adminCopyPermissionParams, options) {
        return AdminApiFp(this.configuration).adminCopyPermissions(userId, adminCopyPermissionParams, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Return basic statistical info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    adminInfo(options) {
        return AdminApiFp(this.configuration).adminInfo(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Return list of logged users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    adminLoggedUsers(options) {
        return AdminApiFp(this.configuration).adminLoggedUsers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creating missing items in arr_cached_node
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    adminSyncNodeCache(options) {
        return AdminApiFp(this.configuration).adminSyncNodeCache(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * DaosApi - axios parameter creator
 * @export
 */
export const DaosApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Změna scénáře napojení dao
         * @param {number} id Identifikátor dao
         * @param {string} body Nový scénář
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLinkScenario: (id, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeLinkScenario', 'id', id);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('changeLinkScenario', 'body', body);
            const localVarPath = `/daos/{id}/change-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DaosApi - functional programming interface
 * @export
 */
export const DaosApiFp = function (configuration) {
    const localVarAxiosParamCreator = DaosApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Změna scénáře napojení dao
         * @param {number} id Identifikátor dao
         * @param {string} body Nový scénář
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLinkScenario(id, body, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.changeLinkScenario(id, body, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['DaosApi.changeLinkScenario']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * DaosApi - factory interface
 * @export
 */
export const DaosApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DaosApiFp(configuration);
    return {
        /**
         *
         * @summary Změna scénáře napojení dao
         * @param {number} id Identifikátor dao
         * @param {string} body Nový scénář
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLinkScenario(id, body, options) {
            return localVarFp.changeLinkScenario(id, body, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DaosApi - object-oriented interface
 * @export
 * @class DaosApi
 * @extends {BaseAPI}
 */
export class DaosApi extends BaseAPI {
    /**
     *
     * @summary Změna scénáře napojení dao
     * @param {number} id Identifikátor dao
     * @param {string} body Nový scénář
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaosApi
     */
    changeLinkScenario(id, body, options) {
        return DaosApiFp(this.configuration).changeLinkScenario(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daoRepositoryFsRepos: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/daorepository`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daoRepositoryFsRepos(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.daoRepositoryFsRepos(options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['DefaultApi.daoRepositoryFsRepos']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DefaultApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daoRepositoryFsRepos(options) {
            return localVarFp.daoRepositoryFsRepos(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    daoRepositoryFsRepos(options) {
        return DefaultApiFp(this.configuration).daoRepositoryFsRepos(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * DescitemsApi - axios parameter creator
 * @export
 */
export const DescitemsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create attribute value
         * @param {number} fundVersionId id AP version
         * @param {NodeItem} nodeItem new attribute value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        descItemCreateDescItem: (fundVersionId, nodeItem, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fundVersionId' is not null or undefined
            assertParamExists('descItemCreateDescItem', 'fundVersionId', fundVersionId);
            // verify required parameter 'nodeItem' is not null or undefined
            assertParamExists('descItemCreateDescItem', 'nodeItem', nodeItem);
            const localVarPath = `/descItems/{fundVersionId}/create`
                .replace(`{${"fundVersionId"}}`, encodeURIComponent(String(fundVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(nodeItem, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update attribute value
         * @param {number} fundVersionId id AP version
         * @param {boolean} createNewVersion create a new version or not?
         * @param {NodeItem} nodeItem new attribute value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        descItemUpdateDescItem: (fundVersionId, createNewVersion, nodeItem, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fundVersionId' is not null or undefined
            assertParamExists('descItemUpdateDescItem', 'fundVersionId', fundVersionId);
            // verify required parameter 'createNewVersion' is not null or undefined
            assertParamExists('descItemUpdateDescItem', 'createNewVersion', createNewVersion);
            // verify required parameter 'nodeItem' is not null or undefined
            assertParamExists('descItemUpdateDescItem', 'nodeItem', nodeItem);
            const localVarPath = `/descItems/{fundVersionId}/{createNewVersion}/update`
                .replace(`{${"fundVersionId"}}`, encodeURIComponent(String(fundVersionId)))
                .replace(`{${"createNewVersion"}}`, encodeURIComponent(String(createNewVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(nodeItem, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DescitemsApi - functional programming interface
 * @export
 */
export const DescitemsApiFp = function (configuration) {
    const localVarAxiosParamCreator = DescitemsApiAxiosParamCreator(configuration);
    return {
        /**
         * Create attribute value
         * @param {number} fundVersionId id AP version
         * @param {NodeItem} nodeItem new attribute value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        descItemCreateDescItem(fundVersionId, nodeItem, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.descItemCreateDescItem(fundVersionId, nodeItem, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['DescitemsApi.descItemCreateDescItem']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Update attribute value
         * @param {number} fundVersionId id AP version
         * @param {boolean} createNewVersion create a new version or not?
         * @param {NodeItem} nodeItem new attribute value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        descItemUpdateDescItem(fundVersionId, createNewVersion, nodeItem, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.descItemUpdateDescItem(fundVersionId, createNewVersion, nodeItem, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['DescitemsApi.descItemUpdateDescItem']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * DescitemsApi - factory interface
 * @export
 */
export const DescitemsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DescitemsApiFp(configuration);
    return {
        /**
         * Create attribute value
         * @param {number} fundVersionId id AP version
         * @param {NodeItem} nodeItem new attribute value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        descItemCreateDescItem(fundVersionId, nodeItem, options) {
            return localVarFp.descItemCreateDescItem(fundVersionId, nodeItem, options).then((request) => request(axios, basePath));
        },
        /**
         * Update attribute value
         * @param {number} fundVersionId id AP version
         * @param {boolean} createNewVersion create a new version or not?
         * @param {NodeItem} nodeItem new attribute value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        descItemUpdateDescItem(fundVersionId, createNewVersion, nodeItem, options) {
            return localVarFp.descItemUpdateDescItem(fundVersionId, createNewVersion, nodeItem, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DescitemsApi - object-oriented interface
 * @export
 * @class DescitemsApi
 * @extends {BaseAPI}
 */
export class DescitemsApi extends BaseAPI {
    /**
     * Create attribute value
     * @param {number} fundVersionId id AP version
     * @param {NodeItem} nodeItem new attribute value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescitemsApi
     */
    descItemCreateDescItem(fundVersionId, nodeItem, options) {
        return DescitemsApiFp(this.configuration).descItemCreateDescItem(fundVersionId, nodeItem, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update attribute value
     * @param {number} fundVersionId id AP version
     * @param {boolean} createNewVersion create a new version or not?
     * @param {NodeItem} nodeItem new attribute value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescitemsApi
     */
    descItemUpdateDescItem(fundVersionId, createNewVersion, nodeItem, options) {
        return DescitemsApiFp(this.configuration).descItemUpdateDescItem(fundVersionId, createNewVersion, nodeItem, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * ExternalsystemsApi - axios parameter creator
 * @export
 */
export const ExternalsystemsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Return list of all properties of external system
         * @param {number} [extSystemId] external system id
         * @param {number} [userId] user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemAllProperties: (extSystemId, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/extsystem/property`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (extSystemId !== undefined) {
                localVarQueryParameter['extSystemId'] = extSystemId;
            }
            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete one property of external system
         * @param {Array<number>} requestBody external system property id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemDeleteProperties: (requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('externalSystemDeleteProperties', 'requestBody', requestBody);
            const localVarPath = `/extsystem/property`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Store one or more property of external system
         * @param {Array<ExtSystemProperty>} extSystemProperty request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemStoreProperties: (extSystemProperty, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'extSystemProperty' is not null or undefined
            assertParamExists('externalSystemStoreProperties', 'extSystemProperty', extSystemProperty);
            const localVarPath = `/extsystem/property`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(extSystemProperty, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ExternalsystemsApi - functional programming interface
 * @export
 */
export const ExternalsystemsApiFp = function (configuration) {
    const localVarAxiosParamCreator = ExternalsystemsApiAxiosParamCreator(configuration);
    return {
        /**
         * Return list of all properties of external system
         * @param {number} [extSystemId] external system id
         * @param {number} [userId] user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemAllProperties(extSystemId, userId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.externalSystemAllProperties(extSystemId, userId, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['ExternalsystemsApi.externalSystemAllProperties']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Delete one property of external system
         * @param {Array<number>} requestBody external system property id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemDeleteProperties(requestBody, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.externalSystemDeleteProperties(requestBody, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['ExternalsystemsApi.externalSystemDeleteProperties']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Store one or more property of external system
         * @param {Array<ExtSystemProperty>} extSystemProperty request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemStoreProperties(extSystemProperty, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.externalSystemStoreProperties(extSystemProperty, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['ExternalsystemsApi.externalSystemStoreProperties']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * ExternalsystemsApi - factory interface
 * @export
 */
export const ExternalsystemsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = ExternalsystemsApiFp(configuration);
    return {
        /**
         * Return list of all properties of external system
         * @param {number} [extSystemId] external system id
         * @param {number} [userId] user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemAllProperties(extSystemId, userId, options) {
            return localVarFp.externalSystemAllProperties(extSystemId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete one property of external system
         * @param {Array<number>} requestBody external system property id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemDeleteProperties(requestBody, options) {
            return localVarFp.externalSystemDeleteProperties(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Store one or more property of external system
         * @param {Array<ExtSystemProperty>} extSystemProperty request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemStoreProperties(extSystemProperty, options) {
            return localVarFp.externalSystemStoreProperties(extSystemProperty, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ExternalsystemsApi - object-oriented interface
 * @export
 * @class ExternalsystemsApi
 * @extends {BaseAPI}
 */
export class ExternalsystemsApi extends BaseAPI {
    /**
     * Return list of all properties of external system
     * @param {number} [extSystemId] external system id
     * @param {number} [userId] user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalsystemsApi
     */
    externalSystemAllProperties(extSystemId, userId, options) {
        return ExternalsystemsApiFp(this.configuration).externalSystemAllProperties(extSystemId, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete one property of external system
     * @param {Array<number>} requestBody external system property id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalsystemsApi
     */
    externalSystemDeleteProperties(requestBody, options) {
        return ExternalsystemsApiFp(this.configuration).externalSystemDeleteProperties(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Store one or more property of external system
     * @param {Array<ExtSystemProperty>} extSystemProperty request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalsystemsApi
     */
    externalSystemStoreProperties(extSystemProperty, options) {
        return ExternalsystemsApiFp(this.configuration).externalSystemStoreProperties(extSystemProperty, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * FundsApi - axios parameter creator
 * @export
 */
export const FundsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Created a new archive file (AS)
         * @param {CreateFund} createFund
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundCreateFund: (createFund, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'createFund' is not null or undefined
            assertParamExists('fundCreateFund', 'createFund', createFund);
            const localVarPath = `/fund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(createFund, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Smazání seznamu hodnot strukturovaného datového typu
         * @param {number} id identifikátor AS
         * @param {Array<number>} requestBody seznam id hodnot strukturovaného datového typu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundDeleteStructureData: (id, requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fundDeleteStructureData', 'id', id);
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('fundDeleteStructureData', 'requestBody', requestBody);
            const localVarPath = `/fund/{id}/structuredObject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Search for archive files (AS)
         * @param {string} [fulltext] vyhledávaný výraz
         * @param {string} [institutionIdentifier] identifikátor instituce (podpora pro kód instituce i UUID přístupového bodu)
         * @param {number} [max] maximální počet výsledků na stránku
         * @param {number} [from] Od kolikátého záznamu se mají výsledky vracet (0 &#x3D; žádné se nepřeskočí)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFindFunds: (fulltext, institutionIdentifier, max, from, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/fund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }
            if (institutionIdentifier !== undefined) {
                localVarQueryParameter['institutionIdentifier'] = institutionIdentifier;
            }
            if (max !== undefined) {
                localVarQueryParameter['max'] = max;
            }
            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {number} nodeId Node ID
         * @param {string} [path]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsCreateDAOLink: (fundId, fsrepoId, nodeId, path, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('fundFsCreateDAOLink', 'fundId', fundId);
            // verify required parameter 'fsrepoId' is not null or undefined
            assertParamExists('fundFsCreateDAOLink', 'fsrepoId', fsrepoId);
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('fundFsCreateDAOLink', 'nodeId', nodeId);
            const localVarPath = `/fund/{fundId}/fsrepo/{fsrepoId}/linkitem/{nodeId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)))
                .replace(`{${"fsrepoId"}}`, encodeURIComponent(String(fsrepoId)))
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {string} path file path in the repository
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItemData: (fundId, fsrepoId, path, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('fundFsRepoItemData', 'fundId', fundId);
            // verify required parameter 'fsrepoId' is not null or undefined
            assertParamExists('fundFsRepoItemData', 'fsrepoId', fsrepoId);
            // verify required parameter 'path' is not null or undefined
            assertParamExists('fundFsRepoItemData', 'path', path);
            const localVarPath = `/fund/{fundId}/fsrepo/{fsrepoId}/item-data`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)))
                .replace(`{${"fsrepoId"}}`, encodeURIComponent(String(fsrepoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {FsItemType} [filterType] Optional filter to return only folders or files. If not specified all items are returned
         * @param {string} [path] Optional path in the repository
         * @param {string} [lastKey] Optional parameter to return next batch of items. See FsItems for more details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItems: (fundId, fsrepoId, filterType, path, lastKey, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('fundFsRepoItems', 'fundId', fundId);
            // verify required parameter 'fsrepoId' is not null or undefined
            assertParamExists('fundFsRepoItems', 'fsrepoId', fsrepoId);
            const localVarPath = `/fund/{fundId}/fsrepo/{fsrepoId}/items`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)))
                .replace(`{${"fsrepoId"}}`, encodeURIComponent(String(fsrepoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (filterType !== undefined) {
                localVarQueryParameter['filterType'] = filterType;
            }
            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }
            if (lastKey !== undefined) {
                localVarQueryParameter['lastKey'] = lastKey;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Return list of filesystem repositories
         * @param {number} fundId Fund ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepos: (fundId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('fundFsRepos', 'fundId', fundId);
            const localVarPath = `/fund/{fundId}/fsrepos`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Getting the AS detail
         * @param {string} id fund ID. Might be ID or fund UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundGetFund: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fundGetFund', 'id', id);
            const localVarPath = `/fund/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Import additional data to the existing fund
         * @param {string} id fund ID. Might be ID or fund UUID
         * @param {string} importType Import type. Only \\\&#39;CSV\\\&#39; is supported
         * @param {File} dataFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundImportFundData: (id, importType, dataFile, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fundImportFundData', 'id', id);
            // verify required parameter 'importType' is not null or undefined
            assertParamExists('fundImportFundData', 'importType', importType);
            // verify required parameter 'dataFile' is not null or undefined
            assertParamExists('fundImportFundData', 'dataFile', dataFile);
            const localVarPath = `/fund/{id}/import`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            if (importType !== undefined) {
                localVarFormParams.append('importType', importType);
            }
            if (dataFile !== undefined) {
                localVarFormParams.append('dataFile', dataFile);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update of archive file (AS)
         * @param {string} id fund ID. Might be ID or fund UUID
         * @param {UpdateFund} updateFund
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundUpdateFund: (id, updateFund, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fundUpdateFund', 'id', id);
            // verify required parameter 'updateFund' is not null or undefined
            assertParamExists('fundUpdateFund', 'updateFund', updateFund);
            const localVarPath = `/fund/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(updateFund, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Return list of item type id & count
         * @param {number} fundId Fund ID
         * @param {number} fundVersionId Fund ID Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundUsedItemTypes: (fundId, fundVersionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fundId' is not null or undefined
            assertParamExists('fundUsedItemTypes', 'fundId', fundId);
            // verify required parameter 'fundVersionId' is not null or undefined
            assertParamExists('fundUsedItemTypes', 'fundVersionId', fundVersionId);
            const localVarPath = `/fund/{fundId}/usedItemtypes/{fundVersionId}`
                .replace(`{${"fundId"}}`, encodeURIComponent(String(fundId)))
                .replace(`{${"fundVersionId"}}`, encodeURIComponent(String(fundVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * FundsApi - functional programming interface
 * @export
 */
export const FundsApiFp = function (configuration) {
    const localVarAxiosParamCreator = FundsApiAxiosParamCreator(configuration);
    return {
        /**
         * Created a new archive file (AS)
         * @param {CreateFund} createFund
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundCreateFund(createFund, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundCreateFund(createFund, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundCreateFund']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Smazání seznamu hodnot strukturovaného datového typu
         * @param {number} id identifikátor AS
         * @param {Array<number>} requestBody seznam id hodnot strukturovaného datového typu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundDeleteStructureData(id, requestBody, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundDeleteStructureData(id, requestBody, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundDeleteStructureData']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Search for archive files (AS)
         * @param {string} [fulltext] vyhledávaný výraz
         * @param {string} [institutionIdentifier] identifikátor instituce (podpora pro kód instituce i UUID přístupového bodu)
         * @param {number} [max] maximální počet výsledků na stránku
         * @param {number} [from] Od kolikátého záznamu se mají výsledky vracet (0 &#x3D; žádné se nepřeskočí)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFindFunds(fulltext, institutionIdentifier, max, from, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundFindFunds(fulltext, institutionIdentifier, max, from, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundFindFunds']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {number} nodeId Node ID
         * @param {string} [path]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsCreateDAOLink(fundId, fsrepoId, nodeId, path, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundFsCreateDAOLink(fundId, fsrepoId, nodeId, path, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundFsCreateDAOLink']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {string} path file path in the repository
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItemData(fundId, fsrepoId, path, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundFsRepoItemData(fundId, fsrepoId, path, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundFsRepoItemData']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {FsItemType} [filterType] Optional filter to return only folders or files. If not specified all items are returned
         * @param {string} [path] Optional path in the repository
         * @param {string} [lastKey] Optional parameter to return next batch of items. See FsItems for more details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItems(fundId, fsrepoId, filterType, path, lastKey, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundFsRepoItems(fundId, fsrepoId, filterType, path, lastKey, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundFsRepoItems']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Return list of filesystem repositories
         * @param {number} fundId Fund ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepos(fundId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundFsRepos(fundId, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundFsRepos']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Getting the AS detail
         * @param {string} id fund ID. Might be ID or fund UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundGetFund(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundGetFund(id, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundGetFund']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Import additional data to the existing fund
         * @param {string} id fund ID. Might be ID or fund UUID
         * @param {string} importType Import type. Only \\\&#39;CSV\\\&#39; is supported
         * @param {File} dataFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundImportFundData(id, importType, dataFile, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundImportFundData(id, importType, dataFile, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundImportFundData']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Update of archive file (AS)
         * @param {string} id fund ID. Might be ID or fund UUID
         * @param {UpdateFund} updateFund
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundUpdateFund(id, updateFund, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundUpdateFund(id, updateFund, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundUpdateFund']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Return list of item type id & count
         * @param {number} fundId Fund ID
         * @param {number} fundVersionId Fund ID Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundUsedItemTypes(fundId, fundVersionId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fundUsedItemTypes(fundId, fundVersionId, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['FundsApi.fundUsedItemTypes']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * FundsApi - factory interface
 * @export
 */
export const FundsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = FundsApiFp(configuration);
    return {
        /**
         * Created a new archive file (AS)
         * @param {CreateFund} createFund
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundCreateFund(createFund, options) {
            return localVarFp.fundCreateFund(createFund, options).then((request) => request(axios, basePath));
        },
        /**
         * Smazání seznamu hodnot strukturovaného datového typu
         * @param {number} id identifikátor AS
         * @param {Array<number>} requestBody seznam id hodnot strukturovaného datového typu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundDeleteStructureData(id, requestBody, options) {
            return localVarFp.fundDeleteStructureData(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Search for archive files (AS)
         * @param {string} [fulltext] vyhledávaný výraz
         * @param {string} [institutionIdentifier] identifikátor instituce (podpora pro kód instituce i UUID přístupového bodu)
         * @param {number} [max] maximální počet výsledků na stránku
         * @param {number} [from] Od kolikátého záznamu se mají výsledky vracet (0 &#x3D; žádné se nepřeskočí)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFindFunds(fulltext, institutionIdentifier, max, from, options) {
            return localVarFp.fundFindFunds(fulltext, institutionIdentifier, max, from, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {number} nodeId Node ID
         * @param {string} [path]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsCreateDAOLink(fundId, fsrepoId, nodeId, path, options) {
            return localVarFp.fundFsCreateDAOLink(fundId, fsrepoId, nodeId, path, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {string} path file path in the repository
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItemData(fundId, fsrepoId, path, options) {
            return localVarFp.fundFsRepoItemData(fundId, fsrepoId, path, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {FsItemType} [filterType] Optional filter to return only folders or files. If not specified all items are returned
         * @param {string} [path] Optional path in the repository
         * @param {string} [lastKey] Optional parameter to return next batch of items. See FsItems for more details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItems(fundId, fsrepoId, filterType, path, lastKey, options) {
            return localVarFp.fundFsRepoItems(fundId, fsrepoId, filterType, path, lastKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Return list of filesystem repositories
         * @param {number} fundId Fund ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepos(fundId, options) {
            return localVarFp.fundFsRepos(fundId, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting the AS detail
         * @param {string} id fund ID. Might be ID or fund UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundGetFund(id, options) {
            return localVarFp.fundGetFund(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Import additional data to the existing fund
         * @param {string} id fund ID. Might be ID or fund UUID
         * @param {string} importType Import type. Only \\\&#39;CSV\\\&#39; is supported
         * @param {File} dataFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundImportFundData(id, importType, dataFile, options) {
            return localVarFp.fundImportFundData(id, importType, dataFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Update of archive file (AS)
         * @param {string} id fund ID. Might be ID or fund UUID
         * @param {UpdateFund} updateFund
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundUpdateFund(id, updateFund, options) {
            return localVarFp.fundUpdateFund(id, updateFund, options).then((request) => request(axios, basePath));
        },
        /**
         * Return list of item type id & count
         * @param {number} fundId Fund ID
         * @param {number} fundVersionId Fund ID Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundUsedItemTypes(fundId, fundVersionId, options) {
            return localVarFp.fundUsedItemTypes(fundId, fundVersionId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * FundsApi - object-oriented interface
 * @export
 * @class FundsApi
 * @extends {BaseAPI}
 */
export class FundsApi extends BaseAPI {
    /**
     * Created a new archive file (AS)
     * @param {CreateFund} createFund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundCreateFund(createFund, options) {
        return FundsApiFp(this.configuration).fundCreateFund(createFund, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Smazání seznamu hodnot strukturovaného datového typu
     * @param {number} id identifikátor AS
     * @param {Array<number>} requestBody seznam id hodnot strukturovaného datového typu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundDeleteStructureData(id, requestBody, options) {
        return FundsApiFp(this.configuration).fundDeleteStructureData(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Search for archive files (AS)
     * @param {string} [fulltext] vyhledávaný výraz
     * @param {string} [institutionIdentifier] identifikátor instituce (podpora pro kód instituce i UUID přístupového bodu)
     * @param {number} [max] maximální počet výsledků na stránku
     * @param {number} [from] Od kolikátého záznamu se mají výsledky vracet (0 &#x3D; žádné se nepřeskočí)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundFindFunds(fulltext, institutionIdentifier, max, from, options) {
        return FundsApiFp(this.configuration).fundFindFunds(fulltext, institutionIdentifier, max, from, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} fundId Fund ID
     * @param {number} fsrepoId FileSystem repository ID
     * @param {number} nodeId Node ID
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundFsCreateDAOLink(fundId, fsrepoId, nodeId, path, options) {
        return FundsApiFp(this.configuration).fundFsCreateDAOLink(fundId, fsrepoId, nodeId, path, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} fundId Fund ID
     * @param {number} fsrepoId FileSystem repository ID
     * @param {string} path file path in the repository
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundFsRepoItemData(fundId, fsrepoId, path, options) {
        return FundsApiFp(this.configuration).fundFsRepoItemData(fundId, fsrepoId, path, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} fundId Fund ID
     * @param {number} fsrepoId FileSystem repository ID
     * @param {FsItemType} [filterType] Optional filter to return only folders or files. If not specified all items are returned
     * @param {string} [path] Optional path in the repository
     * @param {string} [lastKey] Optional parameter to return next batch of items. See FsItems for more details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundFsRepoItems(fundId, fsrepoId, filterType, path, lastKey, options) {
        return FundsApiFp(this.configuration).fundFsRepoItems(fundId, fsrepoId, filterType, path, lastKey, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Return list of filesystem repositories
     * @param {number} fundId Fund ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundFsRepos(fundId, options) {
        return FundsApiFp(this.configuration).fundFsRepos(fundId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Getting the AS detail
     * @param {string} id fund ID. Might be ID or fund UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundGetFund(id, options) {
        return FundsApiFp(this.configuration).fundGetFund(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Import additional data to the existing fund
     * @param {string} id fund ID. Might be ID or fund UUID
     * @param {string} importType Import type. Only \\\&#39;CSV\\\&#39; is supported
     * @param {File} dataFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundImportFundData(id, importType, dataFile, options) {
        return FundsApiFp(this.configuration).fundImportFundData(id, importType, dataFile, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update of archive file (AS)
     * @param {string} id fund ID. Might be ID or fund UUID
     * @param {UpdateFund} updateFund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundUpdateFund(id, updateFund, options) {
        return FundsApiFp(this.configuration).fundUpdateFund(id, updateFund, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Return list of item type id & count
     * @param {number} fundId Fund ID
     * @param {number} fundVersionId Fund ID Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    fundUsedItemTypes(fundId, fundVersionId, options) {
        return FundsApiFp(this.configuration).fundUsedItemTypes(fundId, fundVersionId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * IoApi - axios parameter creator
 * @export
 */
export const IoApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create export file of funds or access points
         * @param {ExportParams} exportParams Export request parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioExportRequest: (exportParams, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'exportParams' is not null or undefined
            assertParamExists('ioExportRequest', 'exportParams', exportParams);
            const localVarPath = `/io/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(exportParams, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Getting the generated file by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportFile: (requestId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('ioGetExportFile', 'requestId', requestId);
            const localVarPath = `/io/file/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Getting the export status by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportStatus: (requestId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('ioGetExportStatus', 'requestId', requestId);
            const localVarPath = `/io/export-status/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * IoApi - functional programming interface
 * @export
 */
export const IoApiFp = function (configuration) {
    const localVarAxiosParamCreator = IoApiAxiosParamCreator(configuration);
    return {
        /**
         * Create export file of funds or access points
         * @param {ExportParams} exportParams Export request parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioExportRequest(exportParams, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.ioExportRequest(exportParams, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['IoApi.ioExportRequest']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Getting the generated file by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportFile(requestId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.ioGetExportFile(requestId, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['IoApi.ioGetExportFile']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Getting the export status by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportStatus(requestId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.ioGetExportStatus(requestId, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['IoApi.ioGetExportStatus']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * IoApi - factory interface
 * @export
 */
export const IoApiFactory = function (configuration, basePath, axios) {
    const localVarFp = IoApiFp(configuration);
    return {
        /**
         * Create export file of funds or access points
         * @param {ExportParams} exportParams Export request parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioExportRequest(exportParams, options) {
            return localVarFp.ioExportRequest(exportParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting the generated file by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportFile(requestId, options) {
            return localVarFp.ioGetExportFile(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting the export status by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportStatus(requestId, options) {
            return localVarFp.ioGetExportStatus(requestId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * IoApi - object-oriented interface
 * @export
 * @class IoApi
 * @extends {BaseAPI}
 */
export class IoApi extends BaseAPI {
    /**
     * Create export file of funds or access points
     * @param {ExportParams} exportParams Export request parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IoApi
     */
    ioExportRequest(exportParams, options) {
        return IoApiFp(this.configuration).ioExportRequest(exportParams, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Getting the generated file by request id
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IoApi
     */
    ioGetExportFile(requestId, options) {
        return IoApiFp(this.configuration).ioGetExportFile(requestId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Getting the export status by request id
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IoApi
     */
    ioGetExportStatus(requestId, options) {
        return IoApiFp(this.configuration).ioGetExportStatus(requestId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArchDesc: (searchParams, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/search-arr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParams, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEntity: (searchParams, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/search-ap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParams, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArchDesc(searchParams, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchArchDesc(searchParams, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['SearchApi.searchArchDesc']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEntity(searchParams, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchEntity(searchParams, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['SearchApi.searchEntity']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration, basePath, axios) {
    const localVarFp = SearchApiFp(configuration);
    return {
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArchDesc(searchParams, options) {
            return localVarFp.searchArchDesc(searchParams, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEntity(searchParams, options) {
            return localVarFp.searchEntity(searchParams, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     *
     * @summary searches for entity
     * @param {SearchParams} [searchParams] search parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    searchArchDesc(searchParams, options) {
        return SearchApiFp(this.configuration).searchArchDesc(searchParams, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary searches for entity
     * @param {SearchParams} [searchParams] search parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    searchEntity(searchParams, options) {
        return SearchApiFp(this.configuration).searchEntity(searchParams, options).then((request) => request(this.axios, this.basePath));
    }
}
