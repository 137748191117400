import React from 'react';

const FileText = props => {
    return (
        <svg {...props}>
            <path d="m 3,0.5 v 19 H 17 V 6 C 15.2,6 13.8,6 12,6 11.4,5.85 11.6,5.16 11.6,4.71 V 0.509 Z m 9.5,0 V 5 H 17 Z M 5.7,9.87 c 2.9,0 5.8,0 8.7,0 0.7,0.12 0.4,1.33 -0.4,1.03 -2.8,0 -5.6,0 -8.4,0 C 5,10.8 5.1,9.85 5.7,9.86 Z m 0.1,2.63 c 2.8,0 5.7,0 8.6,0 0.7,0.1 0.4,1.3 -0.4,1 -2.8,0 -5.6,0.1 -8.4,0 -0.6,-0.1 -0.5,-1.1 0.2,-1 z m 0,2.7 c 2.8,0 5.7,0 8.6,0 0.7,0.1 0.4,1.3 -0.4,1 -2.8,0 -5.6,0 -8.4,0 -0.6,-0.1 -0.5,-1.1 0.2,-1 z" />
        </svg>
    );
};
export default FileText;
