// tslint:disable
/**
 * CLIENT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Typ části:  * `PT_BODY` - tělo  * `PT_CRE` - vznik  * `PT_EVENT` - událost  * `PT_EXT` - zánik  * `PT_IDENT` - identifikátor  * `PT_NAME` - označení  * `PT_REL` - vztah
 * @export
 * @enum {string}
 */
export enum PartType {
    BODY = 'PT_BODY',
    CRE = 'PT_CRE',
    EVENT = 'PT_EVENT',
    EXT = 'PT_EXT',
    IDENT = 'PT_IDENT',
    NAME = 'PT_NAME',
    REL = 'PT_REL'
}



