import React from 'react';

const FileTextPart = props => {
    return (
        <svg {...props}>
            <g>
                <path d="M 3.18,0.781 V 15 L 16.8,7.68 V 6.15 h -5 c -0.3,0 -0.5,-0.22 -0.5,-0.5 V 0.781 Z m 9.12,0 V 5.15 h 4.5 L 12.4,0.781 Z M 12.1,8.27 c 0.7,0.1 0.6,1.07 -0.1,1 H 5.97 c -0.72,0.1 -0.77,-1.02 0,-1 v 0 H 12 Z" />
                <path d="m 7.93,13.8 c -0.73,-0.1 -0.73,1.1 0,1 H 14 c 0.7,0.1 0.7,-1.1 0,-1 z" />
                <path d="M 15.9,9.38 V 18.3 H 4.13 V 15.7 L 3.2,16.2 v 2.6 c 0,0.2 0.23,0.4 0.46,0.4 H 16.4 c 0.2,0 0.4,-0.2 0.4,-0.4 V 8.89 Z" />
            </g>
        </svg>
    );
};
export default FileTextPart;
